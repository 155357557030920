export const checkObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const replaceDotWithComma = (x: string) => {
  let numberWithComma = x.replace('.', ',');
  return numberWithComma.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const numberWithCommas = (x: string | undefined) => {
  if (x === undefined) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const translateComponentNames = (inputStr: string) => {
  switch (inputStr) {
    case 'Wall':
      return 'Wände';
    case 'Window':
      return 'Fenster';
    case 'Door':
      return 'Tür';
    case 'Roof':
      return 'Dach';
    case 'Floor':
      return 'Keller';
    case 'hp':
      return 'Wärmepumpe';
    case 'pv':
      return 'Photovoltaik';
    default:
      return inputStr;
  }
};

export const splitComponentName = (inputStr: string) => {
  if (inputStr.includes('_')) {
    let split = inputStr.split('_');
    return (translateComponentNames(split[2]) + ' ').toString();
  } else {
    return translateComponentNames(inputStr);
  }
};
