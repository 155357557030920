import useTypedForm from './useTypedForm';
import ToolTip from './Tooltip';
import { useEffect, useState } from 'react';
import { fetchQuery } from '../helper/fetchQuery';
import { useFormContext } from 'react-hook-form';
import { GetWindowSize } from '../helper/getWindowSize';
import { InputData } from '../pages/InputPage';

interface GeneralBuildingInputInterface {
  setBuildingAge: (buildingAge: number) => void;
  sizeRangeComponents: any;
  token: string;
  inputFormData: InputData;
  modelAvailable: boolean;
}

interface GeneralBuildingForm {
  buildingAge: number;
  zipCode: number;
  numPersons: number;
  medium_year: boolean;
  extreme_summer: boolean;
  extreme_winter: boolean;
  annualEvCons: number;
}

const GeneralBuildingInput = ({
  setBuildingAge,
  sizeRangeComponents,
  token,
  inputFormData,
  modelAvailable,
}: GeneralBuildingInputInterface) => {
  const { FormInput } = useTypedForm<GeneralBuildingForm>();
  const { formState } = useFormContext();
  const fetchAsync = async (url: string) => {
    return fetchQuery(url, token);
  };

  const [cityName, setCityName] = useState<string>('');

  const checkZipCode = (zipCode: string) => {
    if (token && modelAvailable) {
      fetchAsync(`getCity?zipCode=${zipCode}`).then(data => {
        setCityName(data === undefined ? 'Das ist keine gültige PLZ' : data);
      });
    }
  };

  useEffect(() => {
    checkZipCode(formState.defaultValues!.zipCode.toString());
  }, [token, modelAvailable]);

  const isMobile = GetWindowSize().width < 640;

  return (
    <div className="bg-tertiary rounded-2xl sm:rounded-3xl">
      <div className="pl-7 sm:pl-6 pt-7 flex">
        <h2 className="text-sm sm:text-base font-semibold">
          Allgemeine Gebäudedaten
        </h2>
        <ToolTip mode="hover" position="under">
          Durch die Eingabe der Gebäudedaten und der entsprechenden Parameter
          wird nach energetischen Verbesserungen für dein Gebäude gesucht.
        </ToolTip>
      </div>
      <div className="flex flex-col ml-7 sm:ml-9 mt-5 sm:mt-4 gap-y-3 text-sm pb-6">
        <div className="flex">
          <p className="w-40 sm:w-56">Baujahr des Gebäudes:</p>
          <FormInput
            name={'buildingAge'}
            min={1960}
            max={2023}
            type={'number'}
            rules={{ required: { value: true, message: 'Fehler' } }}
            onChange={data =>
              setBuildingAge(Math.floor(Number(data.target.value) / 10) * 10)
            }
          />
        </div>

        <div className="flex">
          <p className="w-40 sm:w-56">Standort (PLZ):</p>
          <FormInput
            name={'zipCode'}
            type={'number'}
            placeholder={'PLZ'}
            rules={{ required: { value: true, message: 'Fehler' } }}
            onChange={data => checkZipCode(data.target.value)}
          />
          {!isMobile && <p className="ml-3">{cityName}</p>}
        </div>

        <div className="flex">
          <p className="w-40 sm:w-56">Anzahl der Bewohner:</p>
          {sizeRangeComponents && sizeRangeComponents.numPersons && (
            <FormInput
              name={'numPersons'}
              type={'number'}
              min={sizeRangeComponents.numPersons.min}
              max={sizeRangeComponents.numPersons.max}
              placeholder={'Anzahl der Bewohner'}
              rules={{ required: { value: true, message: 'Fehler' } }}
            />
          )}
        </div>

        <div className="flex">
          <p className="w-40 sm:w-56">Jahresverbrauch E-Fahrzeug (kWh):</p>
          {sizeRangeComponents && sizeRangeComponents.annualEvCons && (
            <FormInput
              name={'annualEvCons'}
              type={'number'}
              min={sizeRangeComponents.annualEvCons.min}
              max={sizeRangeComponents.annualEvCons.max}
              placeholder={'Jahresverbrauch E-Fahrzeug'}
              rules={{ required: { value: true, message: 'Fehler' } }}
            />
          )}
        </div>
        <div>
          <div className="flex mt-2">
            <p className="font-semibold">Umweltparameter:</p>
            <ToolTip mode="hover" position="under">
              Initial werden durchschnittliche Umweltparameter berücksichtigt.
              Sollen repräsentative Extremwetterszenarien Einfluss auf die
              Berechnung haben?
            </ToolTip>
          </div>
          <div className="mt-3 flex flex-col sm:flex-row gap-3 sm:gap-7">
            <FormInput
              type={'checkbox'}
              name="medium_year"
              label={'Durchschnittliches Jahr'}
              checked={true}
            />
            <FormInput
              type={'checkbox'}
              name="extreme_summer"
              label={'Extremer Sommer'}
              defaultChecked={inputFormData.extreme_summer}
            />
            <FormInput
              type={'checkbox'}
              name="extreme_winter"
              label={'Extremer Winter'}
              defaultChecked={inputFormData.extreme_winter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralBuildingInput;
