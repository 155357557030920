import { Navbar, Button } from 'react-bootstrap';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest, b2cPolicies } from '../authConfig';
import Header from '../pages/Header';
import { GetWindowSize } from '../helper/getWindowSize';
import logo from '../assets/img/Logout.svg';

export const NavigationBar = () => {
  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch(error => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const handleLogoutPopup = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: '/', // redirects the top level app after logout
    });
  };

  const handleProfileEdit = () => {
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    }
  };

  const isMobile = GetWindowSize().width < 640;

  return (
    <>
      <Navbar bg="primary" variant="dark" className="navbarStyle">
        <Header>
          <AuthenticatedTemplate>
            <Button
              className="w-7 sm:w-22 h-7 sm:bg-white text-black font-semibold text-sm mr-5 rounded-md sm:border-variation-of-primary sm:border-[0.5px]"
              onClick={handleLogoutRedirect}
            >
              {isMobile ? (
                <img src={logo} alt="Ausloggen" className="ml-[3px] w-5" />
              ) : (
                'Ausloggen'
              )}
            </Button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Button
              className="w-7 sm:w-20 h-6 sm:bg-white text-black font-semibold text-sm mr-5 rounded-md sm:border-variation-of-primary sm:border-[0.5px]"
              onClick={handleLoginRedirect}
            >
              {isMobile ? (
                <img src={logo} alt="Ausloggen" className="ml-[3px] w-5" />
              ) : (
                'Einloggen'
              )}
            </Button>
          </UnauthenticatedTemplate>
        </Header>
      </Navbar>
    </>
  );
};
