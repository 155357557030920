import logo from '../assets/img/amperias-logo.svg';
import logoMobile from '../assets/img/amperias-logo-small.svg';
import { GetWindowSize } from '../helper/getWindowSize';

interface HeaderProps {
  children: any;
}
const Header = ({ children }: HeaderProps) => {
  return (
    <div>
      <div className="flex h-16 sm:h-14 justify-between bg-main-blue">
        <div className="w-[168px]">
          <img
            src={GetWindowSize().width < 640 ? logoMobile : logo}
            alt="Amperias Logo"
            className="h-16 sm:h-14 pl-3 py-2"
          />
        </div>
        <div className="grid content-center">
          <h1 className="font-semibold text-xl sm:text-2xl text-white">
            GreenAIHouse
          </h1>
        </div>
        <div className="grid w-[168px] content-center justify-end">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
