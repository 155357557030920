export const fetchQuery = async (
  endpoint: string,
  token: string,
  setIsLoading?: (t: boolean) => void,
) => {
  let data;
  setIsLoading && setIsLoading(true);
  const apiUrl =
    process.env.NODE_ENV === 'development'
      ? `http://localhost/${endpoint}`
      : `https://ven2us-backend.azurewebsites.net/${endpoint}`;

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  });
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  await fetch(apiUrl, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`,
        );
      }
      return response.json();
    })
    .then(actualData => {
      data = actualData;
    })
    .catch(err => {
      console.log(err.message);
    })
    .finally(() => setIsLoading && setIsLoading(false));
  return data;
};
