import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';

export type FormSubmitButtonProps = PropsWithChildren<{
  disabled?: boolean;
}> &
  ButtonHTMLAttributes<HTMLButtonElement>;
function FormSubmitButton({
  children,
  disabled,
  ...rest
}: FormSubmitButtonProps) {
  const { formState } = useFormContext();

  const isDisabled = !formState.isValid || formState.isValidating;
  return (
    <button disabled={disabled || isDisabled} type="submit" {...rest}>
      {children}
    </button>
  );
}
export default FormSubmitButton;
