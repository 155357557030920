import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';
import Footer from '../pages/Footer';

export const PageLayout = props => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <NavigationBar />
      <div>{props.children}</div>
      {/*<AuthenticatedTemplate>
      </AuthenticatedTemplate>*/}
      <Footer />
    </div>
  );
};
