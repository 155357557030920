import { replaceDotWithComma } from '../helper/stringTransformations';
import { ResultType } from '../App';
import { GetWindowSize } from "../helper/getWindowSize";

interface OperativeCostsTableProps {
  result: ResultType | undefined;
}

const OperativeCostsTable = ({ result }: OperativeCostsTableProps) => {
  const isMobile = GetWindowSize().width < 640;
  return (
    <div className="w-full">
      <table className="w-full text-xs sm:text-sm">
        <thead>
          <tr className="bg-main-blue h-16 text-white">
            <th className="w-1/3 text-start pl-2 rounded-tl-xl">
              Operative Kosten (pro Jahr)
            </th>
            <th className="w-1/3 text-start pl-2">Derzeitige Energiekosten</th>
            <th className="w-1/3 text-start pl-2 rounded-tr-xl">
              Spätere Energiekosten
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-tertiary h-16">
            <td className="pl-2">
              {isMobile ? 'Stromkosten' : 'Stromkosten [€]'}
            </td>
            <td className="pl-2">
              {replaceDotWithComma(
                result?.output_data.cost_operation.power_before
                  ?.toFixed(2)
                  .toString()!,
              )}
            </td>
            <td className="pl-2">
              {replaceDotWithComma(
                result?.output_data.cost_operation.power_after
                  ?.toFixed(2)
                  .toString()!,
              )}
            </td>
          </tr>
          <tr className="bg-variation-of-tertiary h-16">
            <td className="pl-2 rounded-bl-xl">
              {isMobile ? 'Heizkosten' : 'Heizkosten [€]'}
            </td>
            <td className="pl-2">
              {replaceDotWithComma(
                result?.output_data.cost_operation.heat_before
                  ?.toFixed(2)
                  .toString()!,
              )}
            </td>
            <td className="pl-2 rounded-br-xl">
              {replaceDotWithComma(
                result?.output_data.cost_operation.heat_after
                  ?.toFixed(2)
                  .toString()!,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OperativeCostsTable;
