import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { GetWindowSize } from "../helper/getWindowSize";

Chart.register(CategoryScale);

export type ChartInputDataType = {
  id: number;
  component: string;
  costBefore: number;
  costAfter: number;
  costExpansion: number;
};

interface ChartProps {
  chartInputData: ChartInputDataType[];
}

const CostChart = ({ chartInputData }: ChartProps) => {
  const [chartData] = useState({
    labels: chartInputData.map((data: any) => data.component),
    datasets: [
      {
        label: 'Aktuelle Energiekosten',
        data: chartInputData.map((data: ChartInputDataType) => data.costBefore),
        backgroundColor: ['#F1697D'],
        borderWidth: 0,
        borderRadius: 5,
      },
      {
        label: 'Investitionskosten',
        data: chartInputData.map(
          (data: ChartInputDataType) => data.costExpansion,
        ),
        backgroundColor: ['#1C3457'],
        borderWidth: 0,
        borderRadius: 5,
      },
      {
        label: 'Zukünftige Energiekosten',
        data: chartInputData.map((data: ChartInputDataType) => data.costAfter),
        backgroundColor: ['#9EDED4'],
        borderWidth: 0,
        borderRadius: 5,
      },
    ],
  });

  const isMobile = GetWindowSize().width < 640;

  return (
    <div className="flex justify-center mt-10 sm:mt-3 mb-8 sm:mb-16">
      <div className="max-w-[68rem] w-full aspect-[1] sm:aspect-[2]">
        <div className="h-full">
          <Bar
            data={chartData}
            options={{
              aspectRatio: isMobile ? 1 : 2,
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    font: {
                      size: isMobile ? 12 : 13,
                    },
                    boxWidth: 11,
                    boxHeight: 11,
                    padding: isMobile ? 5 : 26,
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    font: {
                      size: isMobile ? 12 : 14,
                    },
                    color: '#000000',
                  },
                  title: {
                    padding: isMobile ? 5 : 26,
                    display: true,
                    text: 'Kosten in T €',
                    font: {
                      size: isMobile ? 12 : 14,
                    },
                  },
                },
                x: {
                  ticks: {
                    font: {
                      size: isMobile ? 12 : 14,
                    },
                    color: '#000000',
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CostChart;
