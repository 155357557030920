import {
  numberWithCommas,
  replaceDotWithComma,
  splitComponentName,
} from '../helper/stringTransformations';
import { ResultType } from '../App';
import fullTree from '../assets/img/TreeFull.png';
import emptyTree from '../assets/img/TreeEmpty.png';

interface ReplaceableComponentsTableProps {
  result: ResultType | undefined;
}

const ReplaceableComponentsTable = ({
  result,
}: ReplaceableComponentsTableProps) => {
  const mapActions = () => {
    let tempArr: any[] = [];
    let index = 0;
    const mapCostColor = (value: number) => {
      if (value <= 20) {
        return 'bg-cost-green/[.2]';
      }
      if (value <= 40) {
        return 'bg-cost-green/[.4]';
      }
      if (value <= 60) {
        return 'bg-cost-green/[.6]';
      }
      if (value <= 80) {
        return 'bg-cost-green/[.8]';
      } else {
        return 'bg-cost-green';
      }
    };
    const selectInsulation = (
      obj: { [key: string]: number },
      showKey: boolean,
    ) => {
      let maxKey = null;
      let maxValue = 0;

      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value > maxValue) {
            maxKey = key;
            maxValue = value;
          }
        }
      }
      return showKey ? maxKey ?? '-' : maxValue ? maxValue * 100 : '-';
    };

    const outputDataArray = Object.entries(result!.output_data);

    if (result?.output_data) {
      let i = 0;
      for (const [keyComp, value] of outputDataArray) {
        let relativeCostSaving = Number(
          (
            ((value.cost_before - value.cost_after) / value.cost_before) *
            100
          ).toFixed(1),
        );

        if (value && relativeCostSaving) {
          let numTrees = Math.round(Math.random() * 3);
          tempArr.push(
            <tr
              key={index}
              className={`${
                index % 2 ? 'bg-variation-of-tertiary' : 'bg-tertiary'
              } border-t border-table-border h-12 font-normal`}
            >
              <td
                className={`pl-2 sticky left-0 ${
                  index % 2 ? 'bg-variation-of-tertiary' : 'bg-tertiary'
                } border-r border-table-border`}
              >
                {splitComponentName(keyComp)}
              </td>
              <td className="pl-2">{value.surface_area}</td>
              <td className="pl-2 border-l border-table-border">
                {numberWithCommas(value.cost_expansion.toString())}
              </td>
              <td className="pl-2 border-l border-table-border">
                <div
                  className={`${mapCostColor(
                    relativeCostSaving,
                  )} w-16 text-center rounded-md`}
                >
                  {relativeCostSaving}%
                </div>
              </td>
              <td className="pl-2 w-20 border-l border-table-border">
                {value.insulation && selectInsulation(value.insulation, true)}
              </td>
              <td className="pl-2 border-l border-table-border">
                {value.insulation && selectInsulation(value.insulation, false)}
              </td>
              <td className="pl-2 border-l border-table-border">
                {value.u_value_before}
              </td>
              <td className="pl-2 border-l-[0.01rem] border-table-border">
                {replaceDotWithComma(value.u_value_after.toString())}
              </td>
              <td className={`pl-2 border-l-[0.01rem] border-table-border`}>
                {mapTrees(numTrees, keyComp)}
              </td>
            </tr>,
          );
          index++;
        }
        i = i++;
      }
    }
    return tempArr;
  };

  const mapTrees = (amount: number, key: string) => {
    const full = (key: string, num: number) => {
      return <img key={key + num} src={fullTree} alt="full Tree" />;
    };
    const empty = (key: string, num: number) => {
      return <img key={key + num} src={emptyTree} alt="empty Tree" />;
    };

    switch (amount) {
      case 1:
        return (
          <div className="flex gap-1 h-7">
            {[full(key, 0), empty(key, 1), empty(key, 2)]}
          </div>
        );
      case 2:
        return (
          <div className="flex gap-1 h-7">
            {[full(key, 0), full(key, 1), empty(key, 2)]}
          </div>
        );
      case 3:
        return (
          <div className="flex gap-1 h-7">
            {[full(key, 0), full(key, 1), full(key, 2)]}
          </div>
        );
      default:
        return (
          <div className="flex gap-1 h-7">
            {[empty(key, 0), empty(key, 1), empty(key, 2)]}
          </div>
        );
    }
  };

  return (
    <div className="xl:flex xl:justify-center">
      <div>
        <p className="mb-7 text-sm sm:text-base font-semibold text-secondary">
          Der Tabelle sind alle Bauteile des Gebäudes mit den empfohlenen
          Anpassungen zu entnehmen.
        </p>
        <div className="table-container overflow-x-scroll xl:overflow-auto rounded-xl">
          <table className="w-[1235px] text-sm min-w-full border-separate border-spacing-0">
            <thead>
              <tr className="bg-main-blue h-16 text-white">
                <th className="sticky left-0 bg-main-blue text-start font-semibold pl-2 w-34 border-r border-table-border">
                  Bauteil
                </th>
                <th className="text-start font-semibold pl-2 w-34">
                  Fläche in m&sup2;
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  Investitionskosten in €
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  <div className="whitespace-pre-line w-34">
                    Reduzierte Energiekosten in %
                  </div>
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  Dämmmaterial
                </th>
                <th className="text-start font-semibold pl-2 pr-4 w-34 border-l border-table-border">
                  Dämmmstärke in mm
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  Wärmekoeffizient Ist-Zustand
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  Wärmekoeffizient Optimal-Zustand
                </th>
                <th className="text-start font-semibold pl-2 w-34 border-l border-table-border">
                  Co<sub>2</sub> WIP
                </th>
              </tr>
            </thead>
            <tbody>{mapActions()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReplaceableComponentsTable;
