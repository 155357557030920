import Chart from 'chart.js/auto';
import { CategoryScale, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';
import { Line } from 'react-chartjs-2';
import { useState } from 'react';
import {
  getDatasets,
  WeatherTypeSelector,
  ChartSelector,
  LoadProfileAttributes,
} from './LoadProfileDatasets';
import { GetWindowSize } from '../helper/getWindowSize';
import { ResultType } from '../App';

Chart.register(CategoryScale, TimeScale);

export type LoadProfileDictType = {
  before: {
    heat_demand: number[];
    power_demand: number[];
    ev_load: number[];
    hp_covered: number[];
    pv_energy: number[];
    power_storage: number[];
    grid_residual: number[];
  };
  after: {
    heat_demand: { medium_year: number[] };
    power_demand: number[];
    ev_load: number[];
    hp_covered: { medium_year: number[] };
    pv_energy: { medium_year: number[] };
    power_storage: number[];
    grid_residual: number[];
  };
};

export interface LoadProfileChartInput {
  isYearChart: boolean;
  startIndex: string;
  loadProfileDict: LoadProfileDictType;
  result: ResultType | undefined;
}

const LoadProfileChart = ({
  isYearChart,
  startIndex,
  loadProfileDict,
  result,
}: LoadProfileChartInput) => {
  const year = new Date();
  const [selectionYearChart, setSelectionYearChart] =
    useState<LoadProfileAttributes>('heat_demand');
  const [selectionWeekChart, setSelectionWeekChart] =
    useState<LoadProfileAttributes>('heat_demand');
  const [yearTypeSwitch, setYearTypeSwitch] = useState<string>('medium_year');

  function formatDateToString(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:00:00`;
  }

  const generateLabels = (
    data: any[],
    startDate: string,
    dataPoints: number,
    startIndex?: number,
  ) => {
    let date = new Date(startDate);
    let dateArray: string[] = [];
    const startI = startIndex ?? 0;
    data
      .filter((_e, index) => startI <= index && index < dataPoints + startI)
      .forEach(() => {
        dateArray.push(formatDateToString(date));
        date.setHours(date.getHours() + 1);
      });
    return dateArray;
  };

  const chartDataYear = {
    labels: generateLabels(
      loadProfileDict.before.heat_demand,
      `${year.getFullYear() + 1}-01-01T00:00:00`,
      8759,
    ),
    datasets: getDatasets(
      selectionYearChart,
      0,
      loadProfileDict,
      yearTypeSwitch,
    ),
  };

  const calcHoursBetween = (dateA: string, dateB: string) => {
    const startDate = new Date(dateA);
    const endDate = new Date(dateB[0]);

    const delta = endDate.getTime() - startDate.getTime();
    return Math.floor(delta / 3600000);
  };

  const chartDataWeek = {
    labels: generateLabels(loadProfileDict.before.heat_demand, startIndex, 168),
    datasets: getDatasets(
      selectionWeekChart,
      calcHoursBetween(`${year.getFullYear() + 1}-01-01T00:00:00`, startIndex),
      loadProfileDict,
      yearTypeSwitch,
      2,
    ),
  };

  const isMobile = GetWindowSize().width < 768;
  const xTitle = `Monate in ${year.getFullYear() + 1}`;

  return (
    <div className="flex justify-center">
      <div className="w-full sm:w-[1250px] sm:mx-5 grid grid-cols-1 md:grid-cols-[1fr,288px] gap-8 md:gap-10 lg:gap-20">
        <div className="w-full aspect-[14/10] md:aspect-[2] min-w-0">
          <div className="h-full">
            <Line
              data={isYearChart ? chartDataYear : chartDataWeek}
              options={{
                aspectRatio: isMobile ? 1.4 : 2,
                plugins: {
                  legend: {
                    display: true,
                    labels: {
                      font: {
                        size: isMobile ? 12 : 14,
                        family: 'Raleway',
                      },
                      boxWidth: 11,
                      boxHeight: 11,
                      padding: isMobile ? 5 : 26,
                    },
                  },
                },
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: 'Leistung in kW',
                      padding: isMobile ? 5 : 15,
                    },
                    ticks: { font: { size: 14, family: 'Raleway' } },
                  },
                  x: {
                    title: {
                      display: true,
                      text: xTitle,
                      font: { size: 12, family: 'Raleway' },
                    },
                    type: 'time',
                    ticks: { font: { size: 14 } },
                    time: {
                      unit: isYearChart ? 'month' : 'day',
                      displayFormats: {
                        day: 'dd MMM',
                        month: 'MMM',
                      },
                    },
                    adapters: {
                      date: {
                        locale: de,
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className="w-full md:w-72 flex justify-center self-center">
          {isYearChart ? (
            <div>
              <ChartSelector
                selectionChart={selectionYearChart}
                setSelectionChart={setSelectionYearChart}
              />
              <WeatherTypeSelector
                result={result}
                yearTypeSwitch={yearTypeSwitch}
                setYearTypeSwitch={setYearTypeSwitch}
              />
            </div>
          ) : (
            <div>
              <ChartSelector
                selectionChart={selectionWeekChart}
                setSelectionChart={setSelectionWeekChart}
              />
              <WeatherTypeSelector
                result={result}
                yearTypeSwitch={yearTypeSwitch}
                setYearTypeSwitch={setYearTypeSwitch}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadProfileChart;
