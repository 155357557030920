import { FieldValues } from 'react-hook-form';
import { useMemo } from 'react';
import FormInputField, { FormTextInputProps } from './FormInputField';
import DemodataButton, { FormDemodataButtonProps } from './DemodataButton';
import FormSubmitButton, { FormSubmitButtonProps } from './SubmitButton';

function useTypedForm<TFieldValues extends FieldValues = FieldValues>() {
  return useMemo(
    () => ({
      FormInput(props: FormTextInputProps<TFieldValues>) {
        return <FormInputField {...props} />;
      },
      DemodataButton(props: FormDemodataButtonProps) {
        return <DemodataButton {...props} />;
      },
      SubmitButton(props: FormSubmitButtonProps) {
        return <FormSubmitButton {...props} />;
      },
    }),
    [],
  );
}

export default useTypedForm;
