import { LoadProfileDictType } from './LoadProfileChart';
import React, { useState } from 'react';
import { ResultType } from '../App';
import ToolTip from './Tooltip';
import { GetWindowSize } from '../helper/getWindowSize';

export const getDatasets = (
  selection: any,
  startIndex: number,
  data: LoadProfileDictType,
  selectedYear: string,
  pointRadius1?: number,
) => {
  const borderWidth = 1;
  const pointRadius = pointRadius1 ?? 0;
  const afterColor = '#9EDED4';
  const beforeColor = '#F1697D';

  const generateObject = (
    dataAfter: number[],
    dataBefore: number[],
    labelAfter: string,
    labelBefore: string,
  ) => {
    return [
      {
        data: dataAfter.slice(startIndex),
        borderWidth: borderWidth,
        pointRadius: pointRadius,
        borderColor: afterColor,
        backgroundColor: afterColor,
        label: labelAfter,
      },
      {
        data: dataBefore.slice(startIndex),
        borderWidth: borderWidth,
        pointRadius: pointRadius,
        borderColor: beforeColor,
        backgroundColor: beforeColor,
        label: labelBefore,
      },
    ];
  };

  switch (selection) {
    case 'heat_demand':
      // @ts-ignore
      return generateObject(data.before.heat_demand, data.after.heat_demand[selectedYear],
        'Leistungsbedarf Wärme vorher',
        'Leistungsbedarf Wärme nachher',
      );
    case 'power_demand':
      return generateObject(
        data.before.power_demand,
        data.after.power_demand,
        'Strombedarf vorher',
        'Strombedarf nachher',
      );
    case 'heat_pump':
      // @ts-ignore
      return generateObject(data.before.hp_covered, data.after.hp_covered[selectedYear],
        'Leistung der Wärmepumpe vorher',
        'Leistung der Wärmepumpe nachher',
      );
    case 'photovoltaic':
      // @ts-ignore
      return generateObject(data.before.pv_energy, data.after.pv_energy[selectedYear],
        'Leistung der PV-Anlage vorher',
        'Leistung der PV-Anlage nachher',
      );
    case 'electric_vehicle':
      return generateObject(
        data.before.ev_load,
        data.after.ev_load,
        'Leistung E-Fahrzeug vorher',
        'Leistung E-Fahrzeug nachher',
      );
    case 'power_storage_level':
      return generateObject(
        data.before.power_storage,
        data.after.power_storage,
        'Stromspeicherstand vorher',
        'Stromspeicherstand nachher',
      );
    case 'grid_residual':
      return generateObject(
        data.before.grid_residual,
        data.after.grid_residual,
        'Leistungssumme am Netzknoten vorher',
        'Leistungssumme am Netzknoten nachher',
      );
    default:
      return generateObject(
        data.before.ev_load,
        data.after.ev_load,
        'Leistung E-Fahrzeug vorher',
        'Leistung E-Fahrzeug nachher',
      );
  }
};

const getButtons = (
  id: string,
  text: string,
  setFunc: (a: any) => void,
  getFunc: any,
): React.JSX.Element => {
  return (
    <button
      className="flex"
      onClick={() => {
        setFunc(id);
      }}
    >
      <div
        className={`${
          getFunc === id
            ? 'w-[10px] h-[10px] bg-secondary-neu'
            : 'w-[10px] h-[10px] bg-secondary-neu/20'
        } rounded-full self-center`}
      />
      <p
        className={`ml-4 text-sm hover:font-semibold ${
          getFunc === id && 'font-semibold'
        }`}
      >
        {text}
      </p>
    </button>
  );
};

export type LoadProfileAttributes =
  | 'heat_demand'
  | 'power_demand'
  | 'heat_pump'
  | 'photovoltaic'
  | 'electric_vehicle'
  | 'power_storage_level';

export interface ChartSelectorInterface {
  selectionChart: any;
  setSelectionChart: (a: LoadProfileAttributes) => void;
}

export const ChartSelector = ({
  selectionChart,
  setSelectionChart,
}: ChartSelectorInterface) => {
  const attributes = [
    { id: 'heat_demand', text: 'Leistung durch Bewohnen' },
    { id: 'heat_pump', text: 'Leistung der Wärmepumpe' },
    { id: 'photovoltaic', text: 'Leistung der PV-Anlage' },
    { id: 'electric_vehicle', text: 'Ladeleistung vom Elektroauto' },
    { id: 'power_demand', text: 'Grundstrombedarf' },
    { id: 'grid_residual', text: 'Leistungssumme am Netzknoten' },
    { id: 'power_storage_level', text: 'Stromspeicherstand' },
  ];

  const getAttributeButtons = (list: { id: string; text: string }[]) => {
    let tempArr: React.JSX.Element[] = [];
    list.forEach(e =>
      tempArr.push(getButtons(e.id, e.text, setSelectionChart, selectionChart)),
    );
    return tempArr;
  };

  return (
    <div className="self-center p-3 bg-legend w-72 rounded-lg gap-y-2 flex flex-col h-min">
      {getAttributeButtons(attributes)}
    </div>
  );
};

export interface WeatherSelectorInterface {
  result: ResultType | undefined;
  yearTypeSwitch: string;
  setYearTypeSwitch: (a: string) => void;
}

export const WeatherTypeSelector = ({
  result,
  yearTypeSwitch,
  setYearTypeSwitch,
}: WeatherSelectorInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = GetWindowSize().width < 640;

  const yearSwitchButton = () => {
    const getKeyName = (key: string) => {
      switch (key) {
        case 'medium_year':
          return 'Durchschnittliches Jahr';
        case 'extreme_summer':
          return 'Extremer Sommer';
        case 'extreme_winter':
          return 'Extremer Winter';
        default:
          return '';
      }
    };

    let tempArr: any[] = [];
    Object.keys(result?.load_profiles.after.heat_demand).forEach(key => {
      tempArr.push(
        <div className="flex" key={key}>
          {getButtons(key, getKeyName(key), setYearTypeSwitch, yearTypeSwitch)}
        </div>,
      );
    });
    return tempArr;
  };
  return (
    <div
      className="self-center p-3 mt-5 bg-legend w-72 rounded-lg h-min"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className="absolute translate-x-[233px] -translate-y-0.5">
        <ToolTip
          mode={'hover'}
          position={isMobile ? 'under' : 'right'}
          outsideOpen={isOpen}
          isOutsideOpen={setIsOpen}
        >
          <p>
            Für "Leistung durch Bewohnen", "Leistung der Wärmepumpe" und
            "Leistung der PV-Anlage" gibt es für jedes Extremwetterszenario ein
            zusätzliches Lastprofil.
          </p>
          <p>Zum Anzeigen, wähle hier das gewünschte Wetterszenario:</p>
        </ToolTip>
      </div>
      <div className="flex flex-col gap-y-[7px]">{yearSwitchButton()}</div>
    </div>
  );
};
