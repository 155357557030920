import emptyTree from '../assets/img/TreeEmpty.png';
import fullTree from '../assets/img/TreeFull.png';

const LoadingAnimation = () => {
  return (
    <div className="fixed flex flex-col items-center justify-center bg-blur-white/50 backdrop-blur-[15px] inset-0">
      <div className="flex flex-col items-center justify-center bg-variation-of-tertiary/40 w-[300px] h-[300px] rounded-full">
        <p className="text-2xl font-semibold mb-2">Loading...</p>
        <div className="flex gap-2">
          <div className="static">
            <div className="flex space-x-4 w-14">
              <img src={emptyTree} alt="empty Tree" />
            </div>
            <div
              className={`absolute animate-pulse animate-delay-600 -translate-y-[91px] w-14`}
            >
              <img src={fullTree} alt="full Tree" />
            </div>
          </div>
          <div className="static">
            <div className="flex space-x-4 w-14">
              <img src={emptyTree} alt="empty Tree" />
            </div>
            <div className="absolute animate-pulse1 animate-delay-300 -translate-y-[91px] w-14">
              <img src={fullTree} alt="full Tree" />
            </div>
          </div>
          <div className="static">
            <div className="flex space-x-4 w-14">
              <img src={emptyTree} alt="empty Tree" />
            </div>
            <div className="absolute animate-pulse2 -translate-y-[91px] w-14">
              <img src={fullTree} alt="full Tree" />
            </div>
          </div>
          <div className="static">
            <div className="flex space-x-4 w-14">
              <img src={emptyTree} alt="empty Tree" />
            </div>
            <div className="absolute animate-pulse3 -translate-y-[91px] w-14">
              <img src={fullTree} alt="full Tree" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
