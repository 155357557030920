import * as fs from 'fs/promises';
import { useEffect, useState } from 'react';
import { fetchQuery } from '../helper/fetchQuery';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate } from '@azure/msal-react';
import GeneralBuildingInput from '../components/GeneralBuildingInput';
import MapInputCards from '../components/BuildingInputCards';
import Form from '../components/Form';
import FormSubmitButton from '../components/SubmitButton';
import { checkObjectEmpty } from '../helper/stringTransformations';
import { ResultType } from '../App';
import useTypedForm from '../components/useTypedForm';
import ToolTip from '../components/Tooltip';
import LoadingAnimation from '../components/LoadingAnimation';
import { GetWindowSize } from '../helper/getWindowSize';
import demoResultData from '../assets/demoData/demoResultData.json';

export type NumComponents = {
  num_doors: number;
  num_floors: number;
  num_pv: number;
  num_roofs: number;
  num_walls: number;
  num_windows: number;
  reward_no: number;
};

export type InputData = {
  buildingAge: number;
  zipCode: number;
  numPersons: number;
  medium_year: boolean;
  extreme_summer: boolean;
  extreme_winter: boolean;
  walls1: number;
  //walls2: number;
  heatSinkType: number;
  windows: number;
  doors: number;
  roofs: number;
  floors: number;
  annualEvCons: number;
  uDoors: string;
  uWindows: string;
};

export type BuildingAgeData = {
  ID: string;
  Bauteiltyp: string;
  Startjahr_des_Zeitraums: string;
  U_Wert: string;
};

interface InputPageProps {
  setResult: (a: any) => void;
  result: ResultType;
  inputFormData: InputData;
  setInputFormData: (data: InputData) => void;
  token: string;
}

export type SubmitButtonTypes = 'calculate' | 'change' | 'demo' | '';

const InputPage = ({
  setResult,
  result,
  inputFormData,
  setInputFormData,
  token,
}: InputPageProps) => {
  const navigate = useNavigate();
  const { DemodataButton } = useTypedForm();
  const [formStateIsDirty, setFormStateIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitButtonClicked, setSubmitButtonClicked] =
    useState<SubmitButtonTypes>('');
  const [numComponents, setNumComponents] = useState<NumComponents>();
  const [sizeRangeComponents, setSizeRangeComponents] = useState(Object);
  const [buildingAge, setBuildingAge] = useState<number>(2020);
  const [buildingAgeData, setBuildingAgeData] = useState<BuildingAgeData[]>();
  const [demoDataButtonPressed, setDemoDataButtonPressed] =
    useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string[]>([]);
  const [modelAvailable, setModelAvailable] = useState<boolean>(false);
  const fetchAsync = async (
    url: string,
    setIsLoading?: (t: boolean) => void,
  ) => {
    return fetchQuery(url, token, setIsLoading);
  };

  useEffect(() => {
    if (token) {
      let fetchModelCalled = false;

      const testDataExist = () =>
        fetchAsync('testDataExist').then(data => {
          if (data !== 'Datei ./Model wurde nicht gefunden') {
            setModelAvailable(true);
            clearInterval(interval);
          } else if (!fetchModelCalled) {
            fetchAsync('checkAndCopyModelFiles');
            fetchModelCalled = true;
          }
        });

      testDataExist();

      const interval = setInterval(() => {
        testDataExist();
      }, 10000);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchAsync('numComponents')
        .then(data => setNumComponents(data))
        .then(() =>
          fetchAsync('sizeRangeComponents').then(data =>
            setSizeRangeComponents(data),
          ),
        )
        .then(() =>
          fetchAsync('buildingAge').then(data => setBuildingAgeData(data)),
        );
    }
  }, [token]);

  const generateResultSting = (data: InputData) => {
    let tempArr = [];
    for (const [key, value] of Object.entries(data)) {
      if (
        key === 'medium_year' ||
        key === 'extreme_summer' ||
        key === 'extreme_winter'
      ) {
        if (value) {
          tempArr.push({ key: 'yearType', value: key });
          continue;
        } else {
          continue;
        }
      }
      if (key.includes('walls')) {
        tempArr.push({ key: key.slice(0, 5), value: value });
      } else {
        tempArr.push({ key: key, value: value });
      }
    }

    let resultString = '';
    tempArr.forEach(entry => {
      resultString = resultString + entry.key + '=' + entry.value + '&';
    });
    return resultString;
  };

  const handleSubmit = (data: InputData) => {
    setInputFormData(data);
    if (
      submitButtonClicked === 'calculate' ||
      (!demoDataButtonPressed && result === undefined)
    ) {
      data.buildingAge = Math.floor(data.buildingAge / 10) * 10;
      setShowErrorMessage([]);
      fetchAsync(`model?${generateResultSting(data)}`, setIsLoading).then(
        data => {
          setResult(data);
          navigate('/result');
          window.scrollTo(0, 0);
        },
      );
    } else if (submitButtonClicked === 'change') {
      navigate('/result');
      window.scrollTo(0, 0);
    } else if (submitButtonClicked === 'demo') {
      setResult(demoResultData);
      navigate('/result');
      window.scrollTo(0, 0);
    }
    setDemoDataButtonPressed(false);
  };

  const isMobile = GetWindowSize().width < 640;

  return (
    <AuthenticatedTemplate>
      <div className="sm:w-full mt-7 sm:mt-5 mx-6 sm:mx-0">
        <div className="flex justify-center">
          <div>
            <div className="flex justify-center sm:mt-12">
              <div className="max-w-2xl text-secondary">
                <p className="text-sm sm:text-base font-primary font-semibold">
                  Mit dem GreenAIHouse können Gebäude mit Hilfe von
                  Reinforcement-Learning-Algorithmen auf niedrige Energiekosten
                  hin optimiert werden.
                </p>
                <p className="text-xs sm:text-sm mt-4 font-medium sm:font-semibold">
                  Das Ziel ist die energetische Optimierung von komplexen
                  Gebäuden. Hierbei wird aufbauend auf DIN12831-1 nach der
                  optimalen Kombination von Gebäudeerweiterungen und dem Einsatz
                  energietechnische Bauteile gesucht.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-center max-w-6xl w-full mt-10 bg">
              <Form
                onSubmit={handleSubmit}
                defaultValues={
                  checkObjectEmpty(inputFormData)
                    ? {
                        buildingAge: 2020,
                        zipCode: 70173,
                        numPersons: 3,
                        // eslint-disable-next-line camelcase
                        medium_year: true,
                        // eslint-disable-next-line camelcase
                        extreme_summer: false,
                        // eslint-disable-next-line camelcase
                        extreme_winter: false,
                        walls1: 62,
                        //walls2: 75,
                        heatSinkType: 1,
                        windows: 30,
                        doors: 2,
                        roofs: 62,
                        floors: 80,
                        annualEvCons: 2400,
                      }
                    : inputFormData
                }
                getIsDirty={setFormStateIsDirty}
              >
                <GeneralBuildingInput
                  setBuildingAge={setBuildingAge}
                  sizeRangeComponents={sizeRangeComponents}
                  token={token}
                  inputFormData={inputFormData}
                  modelAvailable={modelAvailable}
                />
                <div className="bg-tertiary mt-10 pb-6 rounded-2xl sm:rounded-3xl">
                  <div className="ml-7 sm:ml-0 sm:pl-6 pt-7 sm:pt-5 mb-3 sm:mb-0 grid grid-cols-3">
                    <h2 className="text-sm sm:text-base font-semibold">
                      Bauteilerfassung
                    </h2>
                    {!isMobile && (
                      <div className="flex mt-2.5">
                        <h3 className="text-sm pl-2.5">Fläche</h3>
                        <ToolTip mode="hover" position="under">
                          Fläche bitte in ganzen m<sup>2</sup> angeben.
                        </ToolTip>
                      </div>
                    )}
                    {!isMobile && (
                      <div className="flex mt-2.5">
                        <h3 className="text-sm pl-2.5">U-Wert</h3>
                        <ToolTip mode="hover" position="under">
                          Der U-Wert des Bauteils bestimmt den
                          Wärmedurchlasskoeffizient in W/(m²K). Hier nicht zu
                          sehende U-Werte werden in Abhängigkeit des Baujahres
                          ermittelt.
                        </ToolTip>
                      </div>
                    )}
                  </div>
                  <MapInputCards
                    numComponents={numComponents}
                    sizeRangeComponents={sizeRangeComponents}
                    buildingAgeData={buildingAgeData}
                    buildingAge={buildingAge}
                  />
                  {!isMobile && (
                    <div className="flex justify-end mt-5 mr-5">
                      <div className="mr-5">
                        <DemodataButton
                          setDemoDataButtonPressed={setDemoDataButtonPressed}
                          setSubmitButtonClicked={setSubmitButtonClicked}
                        />
                      </div>
                      <div>
                        {showErrorMessage.length ? (
                          <p className="text-error-message pt-2">{`Fehlerhafte Eingabe bei ${showErrorMessage}`}</p>
                        ) : null}
                        <FormSubmitButton
                          disabled={isLoading}
                          className="w-[90px] h-7 bg-secondary text-white font-semibold text-sm rounded-md"
                          onClick={() => {
                            setSubmitButtonClicked(
                              formStateIsDirty ? 'calculate' : 'change',
                            );
                          }}
                        >
                          Berechnen
                        </FormSubmitButton>
                      </div>
                    </div>
                  )}
                </div>
                {isMobile && (
                  <div className="w-full flex flex-col items-center mt-7">
                    <div className="mb-4">
                      <DemodataButton
                        setDemoDataButtonPressed={setDemoDataButtonPressed}
                        setSubmitButtonClicked={setSubmitButtonClicked}
                      />
                    </div>
                    <FormSubmitButton
                      disabled={isLoading}
                      className="w-[122px] h-7 bg-secondary text-white font-semibold text-xs rounded-2xl"
                      onClick={() => {
                        console.log(formStateIsDirty);
                        setSubmitButtonClicked(
                          formStateIsDirty ? 'calculate' : 'change',
                        );
                      }}
                    >
                      Berechnen
                    </FormSubmitButton>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </div>
        {isLoading && <LoadingAnimation />}
      </div>
    </AuthenticatedTemplate>
  );
};

export default InputPage;
