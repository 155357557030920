import {
  numberWithCommas,
  replaceDotWithComma,
} from '../helper/stringTransformations';
import { ResultType } from '../App';
import ToolTip from './Tooltip';
import { GetWindowSize } from '../helper/getWindowSize';

interface EnergyGeneratorTableProps {
  result: ResultType | undefined;
}

const EnergyGeneratorTable = ({ result }: EnergyGeneratorTableProps) => {
  const translateHP = (input: string) => {
    switch (input) {
      case 'Air-Source Heat Pump':
        return 'Luftwärmepumpe';
      case 'Ground-Source Heat Pump':
        return 'Erdwärmepumpe';
    }
  };

  const isMobile = GetWindowSize().width < 640;

  return (
    <div className="w-full">
      <table className="w-full text-xs sm:text-sm">
        <thead>
          <tr className="bg-main-blue h-16 text-white">
            <th className="w-1/3 text-start font-semibold pl-2 rounded-tl-xl">
              {isMobile ? "Energie-erzeuger" : "Energieerzeuger"}
            </th>
            <th className="w-1/3 text-start font-semibold pl-2 border-l border-table-border">
              Photovoltaik
            </th>
            <th className="w-1/3 text-start font-semibold pl-2 border-l border-table-border rounded-tr-xl">
              Wärmepumpe
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-tertiary h-16 border-t border-table-border">
            <td className="pl-2">
              {!isMobile ? (
                <div className="flex">
                  <p>Fläche/Kapazität</p>
                  {!isMobile && (
                    <ToolTip mode="hover" position="under">
                      Fläche in m² und Leistung in kW/kWp
                    </ToolTip>
                  )}
                </div>
              ) : (
                <p>Fläche/ Kapazität</p>
              )}
            </td>
            <td className="pl-2 border-l border-table-border">
              {replaceDotWithComma(result?.output_data.pv.area?.toString()!)}
            </td>
            <td className="pl-2 border-l border-table-border">
              {replaceDotWithComma(
                result?.output_data.hp.capacity?.toString()!,
              )}
            </td>
          </tr>
          <tr className="bg-variation-of-tertiary h-16 border-t border-table-border">
            <td className="px-2">
              {!isMobile ? (
                <div className="flex">
                  <p>Investitionskosten</p>
                  <ToolTip mode="hover" position="under">
                    Kosten in €
                  </ToolTip>
                </div>
              ) : (
                <p>Investitions-kosten</p>
              )}
            </td>
            <td className="pl-2 border-l border-table-border">
              {numberWithCommas(
                result?.output_data.pv.cost_expansion.toString(),
              )}
            </td>
            <td className="pl-2 border-l border-table-border">
              {numberWithCommas(
                result?.output_data.hp.cost_expansion.toString(),
              )}
            </td>
          </tr>
          <tr className="bg-tertiary h-16 border-t border-table-border">
            <td className="pl-2 rounded-bl-xl">Typ</td>
            <td className="px-2 border-l border-table-border">
              {result?.output_data.pv.type}
            </td>
            <td className="px-2 border-l border-table-border rounded-br-xl">
              {result?.output_data.hp.type &&
                translateHP(result?.output_data.hp.type)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EnergyGeneratorTable;
