import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-main-blue mt-8 sm:mt-20">
      <div className="flex justify-between h-4 text-[9px] text-white font-normal">
        <p className="ml-5 mt-[2.5px]">© Amperias</p>
        <button
          className="h-4 mr-5 underline"
          onClick={() => {
            navigate('/legal');
            window.scrollTo(0, 0);
          }}
        >
          Impressum & Datenschutz
        </button>
      </div>
    </div>
  );
};
export default Footer;
