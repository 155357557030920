import { useNavigate } from 'react-router-dom';
import CostChart, { ChartInputDataType } from '../components/Chart';
import { AuthenticatedTemplate } from '@azure/msal-react';
import ToolTip from '../components/Tooltip';
import {
  numberWithCommas,
  splitComponentName,
} from '../helper/stringTransformations';
import OperativeCostsTable from '../components/OperativeCostsTable';
import EnergyGeneratorTable from '../components/EnergyGeneratorTable';
import { ResultType } from '../App';
import ReplaceableComponentsTable from '../components/ReplaceableComponentsTable';
import LoadProfileChart from '../components/LoadProfileChart';
import { GetWindowSize } from '../helper/getWindowSize';
import { debounce } from 'chart.js/helpers';
import React, { useState } from 'react';

export type ComponentType = {
  surface_area: number;
  u_value_before: number;
  u_value_after: number;
  cost_before: number;
  cost_expansion: number;
  cost_after: number;
  insulation: any;

  heat_after?: number;
  heat_before?: number;
  power_after?: number;
  power_before?: number;

  capacity?: number;
  type?: string;
  area?: number;
};

type DynamicObjectType = {
  [key: string]: ComponentType;
};

export type OutputDataType = DynamicObjectType;

interface ResultPageProps {
  result: ResultType | undefined;
}

const ResultPage = ({ result }: ResultPageProps) => {
  const navigate = useNavigate();

  const mapChartData = (data: OutputDataType) => {
    let tempArr: ChartInputDataType[] = [];
    let id: number = 0;
    for (const [keyComp, value] of Object.entries(data)) {
      if (keyComp !== 'cost_operation') {
        tempArr.push({
          id: id,
          component: splitComponentName(keyComp),
          costBefore: value.cost_before / 1000,
          costAfter: value.cost_after / 1000,
          costExpansion: value.cost_expansion / 1000,
        });
        id += id;
      }
    }
    return tempArr;
  };

  const getSumEnergieCost = (costPower: number, costHeat: number) => {
    let sum = costPower + costHeat;

    return numberWithCommas(sum.toFixed(0).toString());
  };

  const year = new Date();
  const isMobile = GetWindowSize().width < 640;

  const [startIndex, setStartIndex] = useState<string>(
    `${year.getFullYear() + 1}-01-01T00:00:00`,
  );
  const handleInputChange = debounce(
    (value: string) => setStartIndex(value),
    300,
  );

  return (
    <AuthenticatedTemplate>
      <div className="sm:w-full mx-6 sm:mx-0">
        <div className="flex justify-between">
          {!isMobile && (
            <button
              className="w-22 h-7 ml-3 mt-4 bg-secondary text-white font-semibold text-sm rounded-md"
              onClick={() => navigate('/')}
            >
              <p className="">Zurück</p>
            </button>
          )}
          {result?.actions_dict && (
            <p className="mt-6 sm:mt-16 lg:mt-10 text-base font-semibold text-secondary">
              Die Berechnungen und dargestellten Kosten beziehen sich auf den
              Zeitraum von {year.getFullYear()} bis {year.getFullYear() + 10}.
            </p>
          )}
          <div className="w-[100px]" />
        </div>
        {result?.actions_dict && (
          <div>
            <div className="flex flex-col sm:flex-row w-full justify-center mt-8 text-xl">
              <div className="flex sm:flex-col flex-row font-semibold text-xs sm:text-sm">
                <div className="flex items-center h-17 w-40 sm:h-24 sm:w-48 rounded-[20px] bg-lachsrot-variation text-center place-content-center text-white">
                  <p className="mx-4 sm:mx-0">
                    Energiekosten ohne energetische Gebäudeoptimierung
                  </p>
                </div>
                <div className="flex w-20 sm:w-full flex-col sm:flex-row content-center justify-center mt-6 ml-4 sm:ml-0">
                  <div className="grid content-center justify-center">
                    <p>
                      {getSumEnergieCost(
                        result?.output_data.cost_operation.heat_before!,
                        result?.output_data.cost_operation.power_before!,
                      )}{' '}
                      €/a
                    </p>
                  </div>
                  <div className="translate-x-[17px] translate-y-1">
                    <ToolTip mode="hover" position="under">
                      Die angegebenen Kosten beziehen sich auf einen Zeitraum
                      von 10 Jahre, beginnend bei dem aktuellen Kalenderjahr.
                    </ToolTip>
                  </div>
                </div>
              </div>

              {!isMobile && (
                <div className="grid ml-3 content-center">
                  <div className="flex -translate-y-4">
                    <div className="h-1 ml-9 w-16 bg-secondary" />
                    <div className="mr-9 -translate-y-1 -translate-x-2.5">
                      <div className="w-4 h-1 mb-1 rotate-45 bg-secondary" />
                      <div className="w-4 h-1 -rotate-45 bg-secondary" />
                    </div>
                  </div>
                </div>
              )}

              {isMobile && (
                <div className="flex w-full">
                  <div className="w-[184px]" />
                  <div>
                    <div className="h-8 w-1 bg-secondary translate-x-[28.5px]" />
                    <div className="flex justify-self-center translate-x-[18px]">
                      <div className="w-4 h-1 rotate-45 bg-secondary" />
                      <div className="w-4 h-1 -rotate-45 bg-secondary -translate-x-[7.5px]" />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex sm:flex-col flex-row font-semibold text-xs sm:text-sm">
                <div className="flex sm:flex-col items-center h-17 w-40 sm:h-24 sm:w-48 rounded-[20px] bg-helles-blau text-center place-content-center text-white">
                  <p className="mx-4 sm:mx-0">
                    Energiekosten nach der energetischen Gebäudeoptimierung
                  </p>
                </div>
                <div className="grid w-20 sm:w-full sm:flex-row content-center justify-center ml-4 sm:ml-0 sm:mt-5">
                  <p>
                    {getSumEnergieCost(
                      result?.output_data.cost_operation.heat_after!,
                      result?.output_data.cost_operation.power_after!,
                    )}{' '}
                    €/a
                  </p>
                </div>
              </div>
            </div>

            <div className="sm:py-5 sm:mx-5">
              <CostChart chartInputData={mapChartData(result.output_data)} />
              <ReplaceableComponentsTable result={result} />
              <div className="w-full flex flex-row justify-center mt-10 sm:mt-0">
                <div className="sm:w-[1235px] flex flex-col md:flex-row sm:mt-16 gap-8 lg:gap-20">
                  <div className="w-full">
                    <p className="text-sm sm:text-base pb-7 font-semibold text-secondary">
                      Diese Tabelle listet die Erzeuger auf.
                    </p>
                    <EnergyGeneratorTable result={result} />
                  </div>
                  <div className="w-full">
                    <p className="text-sm sm:text-base pb-7 font-semibold text-secondary">
                      Diese Tabelle zeigt die Kosten auf.
                    </p>
                    <OperativeCostsTable result={result} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 flex justify-center">
              <div className="w-full">
                <div className="flex justify-center sm:mx-5 md:mx-0 mb-8 md:mb-4">
                  <div className="sm:w-[1235px]">
                    <p className="w-full text-sm sm:text-base font-semibold text-secondary-neu">
                      Diesem Diagramm ist das Lastprofil für das Jahr{' '}
                      {year.getFullYear() + 1} zu entnehmen.
                    </p>
                  </div>
                </div>
                <LoadProfileChart
                  isYearChart={true}
                  startIndex={startIndex}
                  loadProfileDict={result.load_profiles}
                  result={result}
                />

                <div className="flex justify-center sm:mx-5 md:mx-0 mt-8 mb-8 md:mb-4">
                  <div className="w-full sm:w-[1250px] text-sm sm:text-base font-semibold text-secondary-neu md:ml-4">
                    <p>
                      Diesem Diagramm ist das Lastprofil für eine beispielhafte
                      Woche aus {year.getFullYear() + 1} zu entnehmen.
                    </p>
                    <p>Passe dazu gerne das Startdatum an.</p>

                    <div className="text-sm sm:text-base w-[226px] sm:w-[272px] h-9 bg-tertiary flex rounded-lg mt-6">
                      <p className="self-center ml-3">Startdatum:</p>
                      <div className="grid w-full">
                        <input
                          className="self-center justify-self-end mr-2 sm:ml-10 p-1.5 w-24 sm:w-32 h-5 rounded-lg"
                          type={'date'}
                          onChange={e => {
                            handleInputChange(e.target.value);
                          }}
                          defaultValue={`${year.getFullYear() + 1}-01-01`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <LoadProfileChart
                  isYearChart={false}
                  startIndex={startIndex}
                  loadProfileDict={result.load_profiles}
                  result={result}
                />
              </div>
            </div>
            <div className="w-full flex justify-center text-sm mt-14 text-secondary-neu">
              <p>
                Die dargestellten Bauteile, deren individuelle Merkmale sowie
                die vorgeschlagenen Ausbaumöglichkeiten beruhen auf
                Testdatensätzen.
              </p>
            </div>
          </div>
        )}
      </div>
    </AuthenticatedTemplate>
  );
};

export default ResultPage;
