import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import React, { InputHTMLAttributes } from 'react';

export type FormTextInputProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<UseControllerProps<TFieldValues>, 'control' | 'shouldUnregister'> &
    InputHTMLAttributes<HTMLInputElement> & { label?: string; big?: boolean };

function FormInputField<TFieldValues extends FieldValues = FieldValues>({
  name,
  defaultValue,
  rules,
  onChange,
  label,
  big,
  ...rest
}: FormTextInputProps<TFieldValues>) {
  const { field, fieldState } = useController({
    name,
    defaultValue,
    rules,
  });

  return (
    <div>
      <div className="flex">
        <input
          className={`pl-[9px] ${
            big
              ? 'w-96 h-12 pl-4 rounded-md'
              : rest.type === 'radio'
              ? 'w-3 h-6'
              : rest.type === 'checkbox'
              ? 'w-[20px] h-4 rounded-md'
              : 'w-[85px] h-6 rounded-md'
          }`}
          {...field}
          onChange={event => {
            onChange?.(event);
            field.onChange(event);
          }}
          value={field.value || ''}
          {...rest}
        />
        {(() => {
          return <></>;
        })()}
        {label && <p className="ml-3">{label}</p>}
      </div>
      {fieldState.error ? (
        <div className="text-red-500">{fieldState.error.message}</div>
      ) : null}
    </div>
  );
}

export default FormInputField;
