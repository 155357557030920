import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { MsalProvider, useMsal } from '@azure/msal-react';
import InputPage, { InputData } from './pages/InputPage';
import ResultPage, { OutputDataType } from './pages/ResultPage';
import { PageLayout } from './components/PageLayout';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import Legal from './pages/Legal';

export type ResultType = {
  actions_dict: any;
  costs_dict: any;
  output_data: OutputDataType;
  load_profiles: any;
};

function ProtectedComponent() {
  const { instance, inProgress, accounts } = useMsal();
  const [result, setResult] = useState<ResultType>();
  const [inputFormData, setInputFormData] = useState<InputData>(Object);
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    const accessTokenRequest = {
      scopes: [
        'openid',
        'offline_access',
        'https://amperiasdemob2c.onmicrosoft.com/6f3c795c-f9c6-4dab-922c-f4ae37e5db29/user_impersonation',
      ],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setAccessToken(accessToken);
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress]);

  return (
    <div className="flex flex-col justify-between">
      <Routes>
        <Route
          path="/"
          element={
            <InputPage
              setResult={setResult}
              // @ts-ignore
              result={result}
              inputFormData={inputFormData}
              setInputFormData={setInputFormData}
              token={accessToken}
            />
          }
        />
        <Route path="/result" element={<ResultPage result={result} />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
    </div>
  );
}

const App = ({ instance }: any) => {
  return (
    <MsalProvider instance={instance}>
      <PageLayout>
        <ProtectedComponent />
      </PageLayout>
    </MsalProvider>
  );
};

export default App;
