import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useEffect, useState } from 'react';
import { GetWindowSize } from '../helper/getWindowSize';

export interface ToolTipProps {
  mode: 'click' | 'hover';
  position: 'right' | 'over' | 'under';
  color?: string;
  width?: string;
  outsideOpen?: boolean;
  isOutsideOpen?: (a: any) => void;
}

function ToolTip({
  mode,
  position,
  children,
  width,
  outsideOpen,
  isOutsideOpen,
}: PropsWithChildren<ToolTipProps>) {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = GetWindowSize().width < 640;

  useEffect(() => {
    if (!isMobile) {
      setOpen(!!outsideOpen);
    }
  }, [outsideOpen]);

  useEffect(() => {
    if (isOutsideOpen && !isMobile) {
      isOutsideOpen(open);
    }
  }, [open]);

  const decideBehaviour = {
    click: {
      onClick: () => setOpen(!open),
    },
    hover: {
      onMouseEnter: () => setOpen(true),
      onMouseLeave: () => setOpen(false),
    },
  };

  return (
    <div
      className="relative w-6 h-6 -translate-y-0.5 ml-2.5"
      {...decideBehaviour[mode || 'click']}
    >
      <button type="button" className="">
        <div className="left-[3.5px] top-1 h-4 w-4 absolute bg-secondary-neu rounded-full" />
        <FontAwesomeIcon
          className={`left-[9px] top-2 h-2 w-2 absolute text-white font-bold text-xs`}
          icon={faInfo}
        />
      </button>
      {open ? (
        <div
          className={`absolute z-10 ${
            position === 'over'
              ? 'bottom-7 -inset-x-28'
              : position === 'under'
              ? 'top-7 -inset-x-28'
              : `left-full -inset-y-20`
          }`}
        >
          {position === 'under' && (
            <div className="flex justify-center">
              <div className="w-0 h-0 border-l-[5px] border-l-transparent border-b-[10px] border-b-secondary-neu border-r-[5px] border-r-transparent" />
            </div>
          )}
          <div className="flex items-center h-full">
            <div className="flex">
              {position === 'right' && (
                <div className="flex items-center">
                  <div className="w-0 h-0 border-t-[5px] border-t-transparent border-b-[5px] border-b-transparent border-r-[10px] border-r-secondary-neu" />
                </div>
              )}
              <div
                className={` ${
                  width ? width : 'w-64'
                } px-3 py-2 text-xs font-[300] text-white rounded-lg min-w-24 bg-secondary-neu whitespace-normal`}
              >
                {children}
              </div>
            </div>
          </div>
          {position === 'over' && (
            <div className="flex justify-center">
              <div className="w-0 h-0 border-l-[5px] border-l-transparent border-t-[10px] border-t-secondary-neu border-r-[5px] border-r-transparent" />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default ToolTip;
