import { useFormContext } from 'react-hook-form';
import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { SubmitButtonTypes } from '../pages/InputPage';

export type FormDemodataButtonProps = PropsWithChildren<{
  setDemoDataButtonPressed: (a: boolean) => void;
  setSubmitButtonClicked: (a: SubmitButtonTypes) => void;
}> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const DemodataButton = ({
  setDemoDataButtonPressed,
  setSubmitButtonClicked,
}: FormDemodataButtonProps) => {
  const { setValue } = useFormContext();
  return (
    <div className="w-full grid">
      <button
        className="w-[122px] h-7 text-secondary-neu font-semibold text-xs sm:text-sm rounded-2xl sm:rounded-md border-secondary-neu border-[0.5px]"
        onClick={() => {
          setValue('zipCode', 70173);
          setValue('yearType', 'medium_year');
          setValue('walls1', 22);
          setValue('walls2', 32);
          setValue('windows', 42);
          setValue('doors', 2);
          setValue('roofs', 62);
          setValue('floors', 72, { shouldDirty: true });
          setDemoDataButtonPressed(true);
          setSubmitButtonClicked('demo');
        }}
      >
        Demodatensatz
      </button>
    </div>
  );
};

export default DemodataButton;
