import { checkObjectEmpty } from '../helper/stringTransformations';
import InputCard from './InputCard';
import { BuildingAgeData, NumComponents } from '../pages/InputPage';

interface MapInputCardsInterface {
  numComponents: NumComponents | undefined;
  sizeRangeComponents: any;
  buildingAgeData: BuildingAgeData[] | undefined;
  buildingAge: number;
}

const MapInputCards = ({
  numComponents,
  sizeRangeComponents,
  buildingAgeData,
  buildingAge,
}: MapInputCardsInterface) => {
  let tempArray = [];
  if (
    numComponents &&
    sizeRangeComponents &&
    !checkObjectEmpty(sizeRangeComponents)
  ) {
    tempArray.push(
      <div key={'Wand'}>
        <InputCard
          title="Fläche der Wände"
          itemName="Wand"
          itemLabel="Wände"
          keyName="walls"
          numberItems={numComponents.num_walls}
          sizeRange={[
            {
              min: sizeRangeComponents.walls.min,
              max: sizeRangeComponents.walls.max,
            },
          ]}
          showUValueInput={false}
        />
      </div>,
    );
    tempArray.push(
      <div key={'Fenster'}>
        <InputCard
          title="Fläche des Fensters"
          itemName="Fenster"
          itemLabel="Fenster"
          keyName="windows"
          numberItems={numComponents.num_windows}
          sizeRange={[
            {
              min: sizeRangeComponents.windows.min,
              max: sizeRangeComponents.windows.max,
            },
            {
              min: sizeRangeComponents.uWindows.min,
              max: sizeRangeComponents.uWindows.max,
            },
          ]}
          showUValueInput={true}
          buildingAgeData={buildingAgeData}
          buildingAge={buildingAge}
        />
      </div>,
    );
    tempArray.push(
      <div key={'Tuer'}>
        <InputCard
          title="Fläche der Tür"
          itemName="Tuer"
          itemLabel="Tür"
          keyName="doors"
          numberItems={numComponents.num_doors}
          sizeRange={[
            { min: 2, max: 2 },
            {
              min: sizeRangeComponents.uDoors.min,
              max: sizeRangeComponents.uDoors.max,
            },
          ]}
          showUValueInput={true}
          deactivateAValueInput={true}
          buildingAgeData={buildingAgeData}
          buildingAge={buildingAge}
        />
      </div>,
    );
    tempArray.push(
      <div key={'Dach'}>
        <InputCard
          title="Fläche des Dachs"
          itemName="Dach"
          itemLabel="Dach"
          keyName="roofs"
          numberItems={numComponents.num_roofs}
          sizeRange={[
            {
              min: sizeRangeComponents.roofs.min,
              max: sizeRangeComponents.roofs.max,
            },
          ]}
          showUValueInput={false}
        />
      </div>,
    );
    tempArray.push(
      <div key={'Keller'}>
        <InputCard
          title="Keller"
          itemName="Keller"
          itemLabel="Keller"
          keyName="floors"
          numberItems={numComponents.num_floors}
          sizeRange={[
            {
              min: sizeRangeComponents.floors.min,
              max: sizeRangeComponents.floors.max,
            },
          ]}
          showUValueInput={false}
        />
      </div>,
    );
    /*tempArray.push(
      <div key={'PV'}>
        PV: Kann bisher Backendseitig noch nicht verarbeitet werden
        <InputCard
          title="PV"
          itemName="PV"
          keyName="pvs"
          numberItems={numComponents.num_pv}
          sizeRange={[{ min: 20, max: 200 }]}
          sumInputValue={sumInputValue}
          setSumInputValue={setSumInputValue}
          showUValueInput={false}
          setError={setError}
          error={error}
        />
      </div>,
    );*/
  }
  /*return tempArray;*/
  return <div>{tempArray}</div>;
};

export default MapInputCards;
