import React, { useEffect, useState } from 'react';
import { BuildingAgeData } from '../pages/InputPage';
import useTypedForm from './useTypedForm';
import { useFormContext } from 'react-hook-form';
import ToolTip from './Tooltip';
import { GetWindowSize } from '../helper/getWindowSize';

export interface InputCardProps {
  title: string;
  itemName: string;
  itemLabel: string;
  keyName: string;
  numberItems: number;
  sizeRange: { min: number; max: number }[];
  showUValueInput: boolean;
  deactivateAValueInput?: boolean;
  buildingAgeData?: BuildingAgeData[];
  buildingAge?: number;
}

interface InputCardForm {
  [key: string]: any;
}

const InputCard = ({
  title,
  itemName,
  keyName,
  itemLabel,
  numberItems,
  sizeRange,
  showUValueInput,
  deactivateAValueInput = false,
  buildingAgeData,
  buildingAge,
}: InputCardProps) => {
  const [uValue, setUValue] = useState<number>(0);

  const { FormInput } = useTypedForm<InputCardForm>();
  const { setValue, getValues, watch } = useFormContext();

  const isMobile = GetWindowSize().width < 640;

  const mapInputFields = (
    itemName: string,
    numberItems: number,
    keyName: string,
  ) => {
    let i = 0;
    let tempArray = [];

    const getKeyName = (keyName: string, i: number) => {
      if (keyName === 'walls') {
        return keyName + i.toString();
      } else {
        return keyName;
      }
    };

    while (i < numberItems) {
      i++;
      tempArray.push(
        <div className="grid grid-cols-2 gap-y-2 py-3 sm:py-1 text-sm" key={i}>
          <div className="flex h-5 sm:h-0">
            <p className="w-[75px] font-semibold sm:font-normal">
              {itemLabel + ':'}
            </p>
            {itemName === 'Wand' && i === 2 && (
              <ToolTip mode="hover" position="under">
                Die Flächen gleich beschaffener Wände können aufsummiert und in
                einer Zeile angegeben werden. Unterscheiden sich die Wände in
                ihrer Struktur nutze bitte eine neue Zeile.
              </ToolTip>
            )}
          </div>
          {isMobile && <div />}
          {isMobile && <p className="italic">Fläche:</p>}
          <FormInput
            name={getKeyName(keyName, i)}
            placeholder={'Anzahl qm'}
            min={sizeRange[0].min}
            max={sizeRange[0].max}
            type={'number'}
            disabled={deactivateAValueInput}
            rules={{ required: { value: true, message: 'Fehler' } }}
          />
        </div>,
      );
    }
    return tempArray;
  };

  function findUValue(array: any, startjahr: string, bauteil: string) {
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].Startjahr_des_Zeitraums === startjahr &&
        array[i].Bauteiltyp === bauteil
      ) {
        return array[i].U_Wert;
      }
    }
    return NaN.toString();
  }

  const generateKeyName = (keyName: string) => {
    return 'u' + keyName.charAt(0).toUpperCase() + keyName.slice(1);
  };

  useEffect(() => {
    if (buildingAgeData && buildingAge) {
      let uValue = findUValue(
        buildingAgeData,
        buildingAge.toString(),
        itemName,
      );
      setUValue(uValue);
      setValue(generateKeyName(keyName), uValue);
    }
  }, [buildingAgeData, buildingAge]);

  return (
    <div className="sm:bg-variation-of-tertiary rounded-xl sm:mt-5 sm:mx-5">
      <div className="grid grid-cols-2 sm:grid-cols-3 sm:py-2">
        <div className="col-span-2 ml-7 sm:ml-5">
          {mapInputFields(itemName, numberItems, keyName)}
        </div>
        {showUValueInput && isMobile && (
          <p className="ml-7 text-sm italic mt-1">U-Wert:</p>
        )}
        {showUValueInput && (
          <div className="text-sm sm:mt-1 ml-[14px] sm:ml-4 mb-3 sm:mb-0">
            <FormInput
              name={generateKeyName(keyName)}
              value={uValue}
              disabled={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputCard;
