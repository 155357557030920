import { FormHTMLAttributes } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form';

export type FormProps<TFieldValues extends FieldValues = FieldValues> =
  UseFormProps<TFieldValues> &
    Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
      onSubmit: SubmitHandler<TFieldValues>;
    } & {
      submitOnChange?: boolean;
      getIsDirty?: (isDirty: boolean) => void;
    };
function Form<TFieldValues extends FieldValues = FieldValues>({
  context,
  criteriaMode,
  defaultValues,
  delayError,
  mode,
  reValidateMode,
  resolver,
  shouldFocusError,
  shouldUnregister,
  shouldUseNativeValidation,
  onSubmit,
  submitOnChange,
  getIsDirty,
  ...rest
}: FormProps<TFieldValues>) {
  const methods = useForm<TFieldValues>({
    context,
    criteriaMode,
    defaultValues,
    delayError,
    mode,
    reValidateMode,
    resolver,
    shouldFocusError,
    shouldUnregister,
    shouldUseNativeValidation,
  });

  getIsDirty && getIsDirty(methods.formState.isDirty);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...rest} />
    </FormProvider>
  );
}
export default Form;
